<template>
  <div>
    <template v-if="$store.getters['app/banners'].dashboard_page_top.img">
      <banner
        :loading="false"
        :banner="$store.getters['app/banners'].dashboard_page_top"
      />
    </template>

    <div class="ps-lg-7 pt-4">
      <h1 class="fs-21 fw-700 mb-5 text-vi">{{ $t('dashboard') }}</h1>

      <v-row v-if="generalSettings.wallet_system == 1">
        <!-- 钱包余额 -->
        <v-col cols="12" sm="4">
          <v-sheet
            color="#95ACFF"
            elevation="0"
            height="130"
            class="d-flex justify-center flex-column card-vi"
          >
            <div class="fs-14 mb-3 white--text">
              {{ $t('wallet_balance') }}
            </div>
            <div class="fw-500 text-h4 white--text">
              {{ format_price(currentUser.balance) }}
            </div>
          </v-sheet>
        </v-col>

        <!-- 最后一次充值的额度 -->
        <v-col cols="12" sm="4">
          <v-sheet
            color="#95ACFF"
            elevation="0"
            height="130"
            class="d-flex justify-center flex-column card-vi"
          >
            <div class="fs-14 mb-3 white--text">
              {{ $t('last_recharge') }}
            </div>
            <div class="fw-500 text-h4 white--text">
              {{ format_price(last_recharge?.amount) }}
            </div>
            <div class="fw-700 fs-12 opacity-60 white--text">{{ last_recharge?.date }}</div>
          </v-sheet>
        </v-col>

        <!-- 充值钱包 -->
        <v-col cols="12" sm="4">
          <recharge-dialog
            :show="rechargeDialogShow"
            :paymentMethods="paymentMethods"
            from="/user/dashboard"
            @onClose="onClose"
            @onRefresh="onRefresh"
          />

          <v-btn
            class="h-100 border py-6 radius-20"
            elevation="0"
            block
            x-large
            @click.stop="rechargeDialogShow = true"
          >
            <span>
              <div class="fs-14 mb-3 w-100 text-vi">{{ $t('recharge_wallet') }}</div>
              <i class="las la-plus la-3x text-vi"></i>
            </span>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="4">
          <v-sheet
            color="product-card"
            elevation="0"
            height="130"
            class="d-flex justify-center flex-column pa-5 product-card-class"
          >
            <div class="fw-500 text-h4 text-vi">{{ getCartCount }}</div>
            <div class="fw-700 fs-12 text-vi">{{ $t('products_in_your_cart') }}</div>
          </v-sheet>
          <v-sheet
            color="product-card"
            rounded="rounded"
            elevation="0"
            height="130"
            class="d-flex justify-center flex-column pa-5 product-card-class text-vi"
          >
            <div class="fw-500 text-h4 text-vi">{{ getTotalWishlisted }}</div>
            <div class="fw-700 fs-12 text-vi">
              {{ $t('products_in_your_wishlist') }}
            </div>
          </v-sheet>
          <v-sheet
            color="product-card"
            rounded="rounded"
            elevation="0"
            height="130"
            class="d-flex justify-center flex-column pa-5 product-card-class"
          >
            <div class="fw-500 text-h4 text-vi">{{ total_order_products }}</div>
            <div class="fw-700 fs-12 text-vi">{{ $t('products_you_ordered') }}</div>
          </v-sheet>
          <banner
            v-if="$store.getters['app/banners'].dashboard_page_bottom.img"
            :loading="false"
            radius="20"
            :banner="$store.getters['app/banners'].dashboard_page_bottom"
          />
        </v-col>

        <v-col cols="12" md="4">
          <v-sheet
            color="border border-gray-200 mb-4"
            height="422"
            elevation="0"
            class="pa-2 radius-20"
          >
            <div class="fw-700 fs-16 border-bottom-vi pb-2 mx-2 my-2 text-vi">
              {{ $t('recent_purchase_history') }}
            </div>
            <div class="h-350px overflow-auto c-scrollbar pa-2">
              <div
                v-for="(product, i) in purchasedProducts"
                :key="i"
                :class="['mt-3', { 'border-top pt-3': i != 0 }]"
              >
                <product-box
                  :product-details="product"
                  :is-loading="dashboardLoading"
                  box-style="two"
                  no-border
                />
              </div>
            </div>
          </v-sheet>
          <v-sheet
            color="border border-gray-200 mb-4"
            rounded="rounded"
            height="405"
            elevation="0"
            class="pa-2 my-wishlist"
          >
            <div class="fw-700 fs-16 border-bottom-vi pb-2 mx-2 my-2 text-vi">
              {{ $t('my_wishlist') }}
            </div>
            <div
              v-if="wislistLoaded && getWislistProducts.length > 0"
              class="h-340px overflow-auto c-scrollbar pa-2"
            >
              <div
                v-for="(product, i) in getWislistProducts"
                :key="i"
                :class="['mt-3', { 'border-top pt-3': i != 0 }]"
              >
                <product-box
                  :product-details="product"
                  :is-loading="!wislistLoaded"
                  box-style="two"
                  no-border
                />
              </div>
            </div>
          </v-sheet>
        </v-col>

        <v-col cols="12" md="4">
          <v-sheet
            color="border mb-4 radius-20"
            elevation="0"
            height="275"
            class="pa-4"
          >
            <div class="fw-700 fs-16 border-bottom pb-2 text-vi">
              {{ $t('default_shipping_address') }}
            </div>
            <div class="mt-3 lh-1-8" v-if="getDefaultShippingAddress">
              <div>{{ getDefaultShippingAddress.address }}</div>
              <div>
                {{ getDefaultShippingAddress.postal_code }},
                {{ getDefaultShippingAddress.city?.name }},
                {{ getDefaultShippingAddress.state?.name }}
              </div>
              <div>{{ getDefaultShippingAddress.country.name }}</div>
              <div>{{ getDefaultShippingAddress.phone }}</div>
            </div>
          </v-sheet>

          <v-sheet elevation="0" height="131" class="mb-4 radius-20">
            <v-btn
              class="border radius-20 fs-14 h-100 primary--text"
              elevation="0"
              block
              x-large
              @click.stop="addressDialogShow = true"
            >
              <i class="las la-plus"></i>
              <span>{{ $t('add_new_address') }}</span>
            </v-btn>
          </v-sheet>

          <address-dialog
            :show="addressDialogShow"
            @close="addressDialogClosed"
          />

          <v-sheet
            color="border radius-20 mb-4"
            height="405"
            elevation="0"
            class="pa-2 recenty-viewed"
          >
            <div class="fw-700 fs-16 border-bottom-vi pb-2 mx-2 my-2 text-vi">
              {{ $t('recently_viewed') }}
            </div>
            <div class="h-340px overflow-auto c-scrollbar pa-2">
              <div
                v-for="(product, i) in getRecentlyViewedProducts"
                :key="i"
                :class="['mt-3', { 'border-top pt-3': i != 0 }]"
              >
                <product-box
                  :product-details="product"
                  :is-loading="!recentlyViewedLoaded"
                  box-style="two"
                  no-border
                />
              </div>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
// components
import RechargeDialog from '../../components/wallet/RechargeDialog.vue'
import AddressDialog from '../../components/address/AddressDialog.vue'
import ProductBox from '../../components/product/ProductBox.vue'

export default {
  data: () => ({
    rechargeDialogShow: false,
    addressDialogShow: false,
    dashboardLoading: true,
    total_order_products: 0,
    purchasedProducts: [{}, {}, {}],
    recentlyViewedProducts: {
      loading: true,
      products: []
    },
    last_recharge: {
      amount: 0,
      date: ''
    }
  }),

  components: {
    RechargeDialog,
    AddressDialog,
    ProductBox
  },

  computed: {
    ...mapState('wallet', ['paymentMethods']),
    ...mapGetters('app', ['generalSettings']),
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('cart', ['getCartCount']),
    ...mapGetters('wishlist', [
      'wislistLoaded',
      'getTotalWishlisted',
      'getWislistProducts'
    ]),
    ...mapGetters('address', ['getDefaultShippingAddress']),
    ...mapGetters('recentlyViewed', [
      'recentlyViewedLoaded',
      'getRecentlyViewedProducts'
    ])
  },

  created() {
    this.getDashboardData()
    this.fetchAddresses()
    this.fetchWislistProducts()
    this.fetchRecentlyViewedProducts()
    this.fetchRechargeMethods()

    // 获取产品查询信息
    this.fetchProductMessage()
    // 获取用户信息(余额)
    this.getUser()
  },

  mounted() {
    this.rechargeWallet(this.$route.query.wallet_payment)
  },

  methods: {
    ...mapActions('app', ['fetchProductMessage']),
    ...mapActions('auth', ['rechargeWallet', 'getUser']),
    ...mapActions('address', ['fetchAddresses']),
    ...mapActions('wishlist', ['fetchWislistProducts']),
    ...mapActions('recentlyViewed', ['fetchRecentlyViewedProducts']),
    ...mapActions('wallet', ['fetchRechargeMethods']),

    async getDashboardData() {
      const {
        data: { data, success }
      } = await this.call_api('get', `user/dashboard`)

      if (success) {
        this.total_order_products = data.total_order_products
        this.purchasedProducts = data.recent_purchased_products.data
        this.last_recharge = data.last_recharge
      }
      this.dashboardLoading = false
    },

    /**
     * 关闭充值钱包弹窗
     */
    onClose() {
      this.rechargeDialogShow = false
    },

    /**
     * 充值钱包成功后的回调
     */
    onRefresh() {
      this.getDashboardData()
      this.getUser()
    },

    addressDialogClosed() {
      this.addressDialogShow = false
    }
  }
}
</script>
<style scoped>
@media (min-width: 960px) and (max-width: 1263px) {
  .my-wishlist,
  .recenty-viewed {
    height: 365px !important;
  }
  .my-wishlist .c-scrollbar,
  .recenty-viewed .c-scrollbar {
    height: 297px;
  }
}
@media (min-width: 1264px) and (max-width: 1903px) {
  .my-wishlist,
  .recenty-viewed {
    height: 350px !important;
  }
  .my-wishlist .c-scrollbar,
  .recenty-viewed .c-scrollbar {
    height: 282px;
  }
}
.fc-vi {
  color: #AF74FF;
}

.card-vi {
  padding-left: 20px;
  border-radius: 20px;
  background-image: url('../../assets/img/wallet-balance-bg.png');
  background-size: cover;
}
.radius-20 {
  border-radius: 20px;
}
.theme--light.v-btn.v-btn--has-bg {
  background: rgba(251, 248, 255, 0.60);
}

.product-card {
  background-image: url(../../assets/img/product-card-bg.png);
  background-size: contain;
}

.product-card-class {
  border-radius: 18px;
  margin-bottom: 16px;
  background-color: transparent;
}
.border-bottom-vi {
  border-bottom: 1px solid #AF74FF;
}
</style>
